import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Top messages`}</h2>
    <p>{`Having processed more than 20 billion chat messages over the past year, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`we`}</a>{` decided to take a look at the top messages users send to Facebook chatbots.`}</p>
    <p>{`“Hi” continues to be the top message sent to bots. It makes sense. Much like with traditional conversation, “hi,” “hello,” and “hey” are common conversation starters.`}</p>
    <p>{`“Thanks” and “stop” continue to be in the top ten as well.`}</p>
    <p>{`The beauty of conversational interfaces is that users will tell you what they want from your bot, and what they think of it afterward. Messages like “thanks” are indications of a positive experience with the bot. “Stop” is a reminder that as bot developers, we need to be mindful of our users’ preferences and to be careful of the frequency of broadcast messaging.`}</p>
    <p>{`As we mentioned in previous posts, it’s important to add support for “stop.” Honoring the user’s request can be the difference between a retained user vs a blocked user. We have a customer who initially didn’t support any stop functionality. They paid to acquire users who eventually blocked them, and they would have to pay to re-acquire them. Adding a mute functionality enabled them to retain the user.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/01/06121425/top-messages-year-50p.png",
        "alt": null
      }}></img></p>
    <h2>{`Bots are international`}</h2>
    <p>{`As we examined before, users aren’t just communicating in English – bot users are international. They’re not just saying hi and hello, but ciao, bonjour, 你好, and привет. In addition to thanks, they say merci, gracias, and 謝謝.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/01/06121424/top-messages-lang-dec-50.png",
        "alt": null
      }}></img></p>
    <p>{`The top ten most common languages sent in to Facebook chatbots during December were English, French, Spanish, Portuguese, German, Arabic, Italian, Russian, Chinese, and Polish.`}</p>
    <p>{`It’s important to look at your analytics and see where your users are coming from and the languages they speak to provide a better user experience.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/01/06121424/language-dec.png",
        "alt": null
      }}></img></p>
    <h2>{`Say it with images`}</h2>
    <p>{`Users aren’t just communicating with words – they use stickers, emoticons, images, audio, video, and more.`}</p>
    <p>{`We took a look at the most common stickers Facebook bots received over the past year.`}</p>
    <p>{`Thumbs up continues to be the most common sticker. Smiling faces, laughing, and love struck eyes are quite common as well.`}</p>
    <p>{`The stickers aren’t just images. They’re another way to communicate feelings and responses.`}</p>
    <p>{`It’s important to monitor the types of stickers and ideally add support to handle them. We had one customer whose bot broke whenever it received the thumbs up sticker – the most common sticker bots receive. Apparently, they weren’t expecting users to communicate in this way. Using our analytics, they were able to see in the message funnel all the conversations that ended once a user sent the thumbs up. They used this information to add support for stickers and improve the overall user experience.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/01/06121423/stickers-year-2017-50p.png",
        "alt": null
      }}></img></p>
    <p>{`We also looked at the most common stickers based on users’ languages over the month of December.`}</p>
    <p>{`The thumbs up is still the most common across languages. While the various smiling, laughing, and sad faces are also quite common, it’s interesting to see the other stickers like the pusheen cats, dogs, and other characters.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/01/06121421/stickers-lang-dec-50.png",
        "alt": null
      }}></img></p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Facebook Messenger, Alexa, Google Home, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      